import React, { useEffect, useRef, useState } from "react";
import "./navbar.css";
import logo from "../../img/logo.svg";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import Header from "../header/Header";
import Footer from "../footer/Footer";
import Copyrights from "../copyrights/Copyrights";

const Navbar = ({ setDirection, direction }) => {
  const [isChecked, setChecked] = useState(false);
  const myElementRef = useRef(null);
  let offsetLink = -100;
  const location = useLocation();
  const [pathLinkName, setPathLinkName] = useState(true);

  const handleToggle = () => {
    setChecked(!isChecked);
  };

  const { i18n, t } = useTranslation();

  const ChangeEN = () => {
    i18n.changeLanguage("en");
    setDirection("ltr");
  };

  const ChangeAR = () => {
    i18n.changeLanguage("ar");
    setDirection("rtl");
  };

  const links = [
    { to: "header", text: t("home") },
    { to: "about", text: t("about_us") },
    { to: "showcasing", text: t("showcasing") },
    { to: "layout", text: t("layout") },
    { to: "event_flow", text: t("event_flow") },
  ];

  useEffect(() => {
    if (myElementRef.current) {
      const height = myElementRef.current.clientHeight;

      offsetLink = height + 100;
    }

    if (location.pathname != "/") {
      setPathLinkName(false);
    } else {
      setPathLinkName(true);
    }

    setTimeout(() => {
      if (location.hash) {
        const targetId = location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        const height = myElementRef.current.clientHeight;

        if (targetElement) {
          const offset = height;
          const elementTop =
            targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetTop = elementTop - offset;

          window.scrollTo({ top: offsetTop, behavior: "smooth" });
        }
      }
    }, 300);
  }, [location.pathname]);

  return (
    <>
      <nav ref={myElementRef}>
        <input
          type="checkbox"
          id="nav-toggle"
          checked={isChecked}
          onChange={handleToggle}
        />

        <NavLink to="/" className="no_active logo">
          <img src={logo} alt="logo" />
        </NavLink>

        <ul className="links" onClick={handleToggle}>
          {links.map((item, i) => (
            <li key={i}>
              {item.to === "header" || item.to === "about" ? (
                <Link
                  onClick={handleToggle}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={offsetLink}
                  duration={500}
                >
                  {item.text}
                </Link>
              ) : pathLinkName ? (
                <Link
                  onClick={handleToggle}
                  to={item.to}
                  spy={true}
                  smooth={true}
                  offset={offsetLink}
                  duration={500}
                >
                  {item.text}
                </Link>
              ) : (
                <NavLink to={`/#${item.to}`}>{item.text}</NavLink>
              )}
            </li>
          ))}

          {direction == "ltr" ? (
            <li>
              <button className="ChangeLang" onClick={ChangeAR}>
                AR
              </button>
            </li>
          ) : (
            <li>
              <button className="ChangeLang" onClick={ChangeEN}>
                EN
              </button>
            </li>
          )}
        </ul>

        <NavLink className="no_active go_about" to="about">
          {t("discover_now")}
        </NavLink>

        <label htmlFor="nav-toggle" className="icon-burger">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>

      <Header />
      <Outlet />

      <Footer offsetLink={offsetLink} myElementRef={myElementRef} />
      <Copyrights />
    </>
  );
};

export default Navbar;
