import React from "react";
import "./layout.css";
import { useTranslation } from "react-i18next";
import layout from "../../../img/layout.webp";

const Layout = () => {
  const { t } = useTranslation();

  return (
    <div id="layout">
      <h1>{t("layout")}</h1>
      <div className="layout">
        <img src={layout} alt="layout" />
      </div>
    </div>
  );
};

export default Layout;
