export const arabicDataAr = {
  home: "الصفحة الرئيسية",
  about_us: "من نحـــــن",
  showcasing: "العارضين",
  layout: "خريطة المعرض",
  event_flow: "جدول المعرض",
  discover_now: "أكتشف الآن",
  header_text_1: "حيث تلتقي الأناقة بالفخامة",
  header_text_2: "معــــرض مَـــــدّ التجـــاري",
  header_text_3: "فندق بولمان, الخليج الغربي, الدوحة - قطر",
  header_text_4: "١-٤ فبراير ٢٠٢٤",
  learn_more: "للمزيد الآن",
  /////////////////////
  about_text_1: "تجربة استثنائية",
  about_text_2:
    "المعرض الأول من نوعه الذي يجسد المزيج بين الفخامة والأناقــــــــــة.",
  about_text_3:
    "انغمس في عالم يلتقي فيه الابداع مع الابتكار، وهو مصمم للكشـف عن أرقى ما في أسلوب الحياة والأزياء والإكسسوارات والمجوهـــرات والصحة والجمال والمزيد، في تجربة لم يسبق لها مثيـــــل.",
  about_text_4:
    "انضم إلينا في قلب قطر حيث نحتفل بمزيج سلس من الرقي والإبـداع وندعوك لتجربة عالم حيث يكون الاستثنائي هو القاعـــــدة",
  about_text_5: "هناك المزيـــــد الذي ستراه عينك!",
  /////////////////////
  arab_fashion: "أزياء عربية",
  international_fashion: "أزياء عالمية",
  /////////////////////
  layout: "خريطة المعرض",
  /////////////////////
  event_flow: "جدول المعرض",
  day_1: "اليــــــوم الأول",
  day_2: "اليــــــوم الثاني",
  day_3: "اليــــــوم الثالث",
  day_4: "اليــــــوم الرابع",
  day_1_t: "إفتتاح المعرض",
  day_2_t: "عروض حيــــــة",
  event_flow_1:
    "* المعرض مفتوح للعامة ويتخلل جميع الأيام عروض شعبية فلكلوريـــــــــــــــــــــــــة",
  event_flow_2: "فرصــــــــة",
  event_flow_3: "ذهبيـــــــة",
  event_flow_4:
    "في هذا التجمع ستجد وتتعرف على مصممين وموردين ومقدمي الخدمات المشهورين والمبدعين في نطاقات متعددة. اكتشف الإمكانيات",
  event_flow_5:
    "اللامحدودة للعلامات التجارية العالمية ذات المبيعات المربحة وفوائد واسعة النطاق",
  ///////////////////
  date: "الوقـــــــــــــــت",
  date_1: "الخميس، 1 فبراير",
  date_2: "الجمعـــة، 2 فبراير",
  date_3: "السبــــت، 3 فبراير",
  date_4: "الأحـــــــد، 4 فبراير",
  ////
  exhibition: "وقت المعـــرض",
  exhibition_1: "11 ص لــ 10 م",
  exhibition_2: "2 م لــ 11 م",
  exhibition_3: "10 ص لــ 10 م",
  exhibition_4: "10 ص لــ 10 م",
  ////
  audience: "الجمهـــــور",
  audience_1: "عـــــــــــــــــــــــــام",
  register: "سجل الآن!",
  /////////////////
  about_us_text_1:
    "منصة للتجارة الإلكترونية التي تسوق للعلامات التجارية ومنتجاتهم، والتي تتبنى مفاهيم تسهيل وتنمية التجارة بين الشركات والزبائـــــــــــــــن",
  about_us_text_2: `مع عرض أفاق جديدة من القنوات لشبكة واسعة النطاق من البائعين في قطر والهند وغيرها من الدول ، بحلول عام
  سنعزز خبرتنا بالالتزام والابتكـــــــــــار والانتشار العالمــــــــــــي 2024
  منصة مينا هاب ترحب بكم - متجر الكتروني قطـــــــــــــــري
  تمثلها السيدة أسماء الكواري بحضور ديناميكي في عالم الأعمال والموضة وباعتبارها العقل المبدع وراء أسماء دبليو ديزاين وتعمـــــــــــــــــل أسماء
  `,
  about_us_text_3:
    "على رعاية المصممين المحليين بروحها الريادية وخبرتها والتي تجعلها قوة دافعة للتغيير الإيجابي والنمو في قطر وخارجها",
  /////////////////
  about_us_text_4: "أوريكس الدوحــــــــة للأزياء 2003",
  about_us_text_5: `
  منظومة قطرية متكاملة لصناعة الأزياء العصرية وفق رؤية قطرية يمتزج فيها إلهام المستقبل بروح الماضــــــي, تسعى لاستقطاب الامكانات المادية والبشرية لصناعة بصمة في عالم الأزياء حول العالم من خلال قيادة فرق عمل احترافيـــــــة متخصصة في مجال التصميم, وادارة الانتاج والجــــــــــــودة وتوج ذلك بحصولها علـــــــى 
  شهادة الأيزو العالمية : وهي معتمدة لتنفيذ مناقصات بدولة قطر في مجال الأزياء النظامية الموحدة - الأزياء التراثيـة تملكها السيدة أمل صالح اليافعــــــــــــــــــي.
  `,
  about_us_text_6: `
  كما تملك سلسلة من شركات الأزياء داخل دولة قطر وخارجها منها أوريكس الدوحة للأزياء الليلك للأزياء ويافي للتجـارة واكاديمية يافي للتدريب الاداري والمهنـــــــــــــــــــــــي
   كما لديها الخبرة في تنظيم وإقامة معارض معارض وفعاليات محلية ودوليـــــــــــــة.
  `,
  /////////////////
  about_us_text_7: "اناهيــــــــــــــــــــــــد 2016",
  about_us_text_8: `
  شركة قطرية متخصصة في الازياء بجودة ودقة وبأسعار المناسبة.
  لديها علامات تجارية مثل نارنجا لاين للملابس اليومية والسفر, وخط انتاج الزي الرسمي الموحد كزي المدارس والمستشفيات والشركات والفنادق والضيافة
  تديرها السيدة / طرفة سعد الزراع كما تملك شركة الطرفة لإعداد وتنظيم المعارض والمؤتمرات والورش والفعاليات.
   بدأت نشاطها 2023 بإقامة معرض ود خلال الفترة من 5 - 8 فبراير 2023, ومعرض بوتيك هاء ستايل بتاريخ 28 اكتوبر 2023
  `,
  /////////////////
  about_us_text_9: "نيـــــرو فاشــــــــــــون 2015",
  about_us_text_10: `
 شركة قطرية متخصصة في تصميم وخياطة الأزياء النسائيـــــــــــــــــة، تم تأسيسها وادارتها من
السيدة / عائشة المطوع والتي حازت على ثقة علامة تجارية عالمية اجنر AIGNER  والتي أصبحت المصممة المعتمدة لعباءاتهم القطرية كما انها ساهمت في تقديم الازياء القطرية لبعض كبار الشخصيات المهمة وزوجات الرؤساء المعروفين وكــــــــــــــــــذلك بطلات عالميات
في المجال الرياضي كما انها مؤسســـــــــــة لمشاريع رياضيـــــــة تهتم بالأســـــــــــرة منذ عام 2018
  `,
};

export default arabicDataAr;
