import React from "react";
import Showcasing from "./showcasing/Showcasing";
import Layout from "./layout/Layout";
import AboutHome from "./aboutHome/AboutHome";
import EventFlow from "./eventFlow/EventFlow";

const Home = () => {
  return (
    <>
      <AboutHome />
      <Showcasing />
      <Layout />
      <EventFlow />
    </>
  );
};

export default Home;
