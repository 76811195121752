import React from "react";
import { useTranslation } from "react-i18next";
import "./aboutHome.css";
import { NavLink } from "react-router-dom";
import about_image from "../../../img/about_image.webp";

const AboutHome = () => {
  const { t } = useTranslation();

  return (
    <div id="about">
      <h1>{t("about_us")}</h1>
      <div className="about_home">
        <div className="left">
          <h2>{t("about_text_1")}</h2>
          <p>{t("about_text_2")}</p>
          <p>{t("about_text_3")}</p>
          <p>{t("about_text_4")}</p>
          <h3>{t("about_text_5")}</h3>

          <NavLink className="go_about" to="about">
            {t("discover_now")}
          </NavLink>
        </div>
        <div className="right">
          <div className="about_image">
            <img src={about_image} alt="about_image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutHome;
