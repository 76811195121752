import React from "react";
import "./showcasing.css";
import { useTranslation } from "react-i18next";
import showcasing_1 from "../../../img/showcasing_1.webp";
import showcasing_2 from "../../../img/showcasing_2.webp";
import showcasing_3 from "../../../img/showcasing_3.webp";
import showcasing_4 from "../../../img/showcasing_4.webp";
import showcasing_5 from "../../../img/showcasing_5.webp";

import international_fashion_1 from "../../../img/international_fashion_1.webp";
import international_fashion_2 from "../../../img/international_fashion_2.webp";
import international_fashion_3 from "../../../img/international_fashion_3.webp";
import international_fashion_4 from "../../../img/international_fashion_4.webp";
import international_fashion_5 from "../../../img/international_fashion_5.webp";
import international_fashion_6 from "../../../img/international_fashion_6.webp";

const Showcasing = () => {
  const { t } = useTranslation();

  return (
    <div id="showcasing">
      <h1>{t("showcasing")}</h1>
      <div className="showcasing">
        <h2 className="arab_fashion">
          <span className="span"></span>
          {t("arab_fashion")}
          <span className="span"></span>
        </h2>
        <div className="images_top">
          <div className="img_1">
            <div>
              <img src={showcasing_1} alt="showcasing_1" />
            </div>
            <div>
              <img src={showcasing_2} alt="showcasing_2" />
            </div>
          </div>
          <div className="img_2">
            <div>
              <img src={showcasing_3} alt="showcasing_3" />
            </div>
            <div>
              <img src={showcasing_4} alt="showcasing_4" />
            </div>
            <div>
              <img src={showcasing_5} alt="showcasing_5" />
            </div>
          </div>
        </div>
        <h2 className="arab_fashion">
          <span className="span"></span>
          {t("international_fashion")}
          <span className="span"></span>
        </h2>
        <div className="images_bottom">
          <div>
            <img src={international_fashion_1} alt="international_fashion_1" />
          </div>

          <div className="img_between">
            <div>
              <img
                src={international_fashion_3}
                alt="international_fashion_3"
              />
              <img
                src={international_fashion_5}
                alt="international_fashion_5"
              />
            </div>

            <div>
              <img
                src={international_fashion_4}
                alt="international_fashion_4"
              />
              <img
                src={international_fashion_6}
                alt="international_fashion_6"
              />
            </div>
          </div>

          <div>
            <img src={international_fashion_2} alt="international_fashion_2" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Showcasing;
