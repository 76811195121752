import React, { useEffect, useState } from "react";
import "./header.css";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { LuMapPin } from "react-icons/lu";
import logo_l from "../../img/logo_l.svg";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { Link } from "react-scroll";

const Header = () => {
  const { t } = useTranslation();
  const [showAbout, setShowAbout] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (location.pathname == "/about") {
      setShowAbout(true);
    } else {
      setShowAbout(false);
    }
  }, [location.pathname]);

  return (
    <div id="header">
      <div className="header">
        <div className="text">
          <h2>{t("header_text_1")}</h2>
          <p>{t("header_text_2")}</p>
          <p>
            <LuMapPin /> {t("header_text_3")}
          </p>
          <p>{t("header_text_4")}</p>

          <div className={`link_header ${showAbout ? "about" : ""}`}>
            <NavLink to="/">
              <img src={logo_l} alt="logo" />
            </NavLink>

            <Link
              to="about"
              spy={true}
              smooth={true}
              offset={-200}
              duration={500}
              className="about"
            >
              {t("learn_more")}
            </Link>

            {showAbout ? (
              <NavLink to="/">
                <FaChevronLeft />
              </NavLink>
            ) : (
              <NavLink to="about">
                <FaChevronRight />
              </NavLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
