import React, { useEffect, useState } from "react";
import "./footer.css";
import { Link } from "react-scroll";
import logo from "../../img/logo.svg";
import { FaWhatsapp, FaInstagram, FaTiktok } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaSnapchat } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation } from "react-router-dom";
import { LuFacebook } from "react-icons/lu";
import { FaXTwitter } from "react-icons/fa6";

const Footer = ({ offsetLink, myElementRef }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [pathLinkName, setPathLinkName] = useState(true);

  useEffect(() => {
    if (myElementRef.current) {
      const height = myElementRef.current.clientHeight;

      offsetLink = height + 100;
    }

    if (location.pathname != "/") {
      setPathLinkName(false);
    } else {
      setPathLinkName(true);
    }

    setTimeout(() => {
      if (location.hash) {
        const targetId = location.hash.substring(1);
        const targetElement = document.getElementById(targetId);
        const height = myElementRef.current.clientHeight;

        if (targetElement) {
          const offset = height;
          const elementTop =
            targetElement.getBoundingClientRect().top + window.scrollY;
          const offsetTop = elementTop - offset;

          window.scrollTo({ top: offsetTop, behavior: "smooth" });
        }
      }
    }, 300);
  }, [location.pathname]);

  const links = [
    { to: "header", text: t("home") },
    { to: "about", text: t("about_us") },
    { to: "showcasing", text: t("showcasing") },
    { to: "layout", text: t("layout") },
    { to: "event_flow", text: t("event_flow") },
  ];

  const social_media = [
    { to: "https://www.instagram.com/mdevents.qa/", icon: <FaInstagram /> },
    {
      to: "https://web.facebook.com/people/Md-Events/61554008902349/",
      icon: <LuFacebook />,
    },
    { to: "https://www.snapchat.com/add/mdeventsqa", icon: <FaSnapchat /> },
    { to: "https://www.tiktok.com/@mdeventsqa", icon: <FaTiktok /> },
    { to: "https://twitter.com/MdEventsqa", icon: <FaXTwitter /> },
  ];

  return (
    <div id="contact">
      <div className="contact">
        <div className="all_contact">
          <div className="left">
            <NavLink to="/" className="logo">
              <img src={logo} alt="logo" />
            </NavLink>
          </div>

          <div className="center">
            <a
              className="whatsapp"
              href="https://api.whatsapp.com/send?phone=97430059780"
              target="_blank"
            >
              <FaWhatsapp />
              +974 3005 9780
            </a>

            <a
              className="mailto"
              href="mailto:info@mdevents.qa"
              target="_blank"
            >
              <MdOutlineEmail /> info@mdevents.qa
            </a>

            <div>
              {social_media.map((item, i) => (
                <a href={item.to} target="_blank" key={i}>
                  {item.icon}
                </a>
              ))}
            </div>
          </div>

          <div className="right">
            <ul className="links">
              {links.map((item, i) => (
                <li key={i}>
                  {item.to === "header" || item.to === "about" ? (
                    <Link
                      to={item.to}
                      spy={true}
                      smooth={true}
                      offset={offsetLink}
                      duration={500}
                    >
                      {item.text}
                    </Link>
                  ) : pathLinkName ? (
                    <Link
                      to={item.to}
                      spy={true}
                      smooth={true}
                      offset={offsetLink}
                      duration={500}
                    >
                      {item.text}
                    </Link>
                  ) : (
                    <NavLink to={`/#${item.to}`}>{item.text}</NavLink>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
