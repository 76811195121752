import React, { useEffect } from "react";
import NotFound from "./page/notFound/NotFound";
import { Route, Routes, useLocation } from "react-router-dom";
import { useAppContext } from "./components/context/AppProvider";
import Navbar from "./components/navbar/Navbar";
import Home from "./page/home/Home";
import About from "./page/About/About";

const App = () => {
  const location = useLocation();

  const { direction, setDirection } = useAppContext();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={`direction_${direction}`} style={{ direction: direction }}>
      <Routes>
        <Route
          path="/"
          element={<Navbar setDirection={setDirection} direction={direction} />}
        >
          <Route path="/" element={<Home />} />
          <Route path="about" element={<About />} />
        </Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
};

export default App;
