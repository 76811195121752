import React, { useEffect } from "react";
import AboutHome from "../home/aboutHome/AboutHome";
import logoMenahub from "../../img/logo_about_1.webp";
import logoMenahub_2 from "../../img/about.webp";

import logoMenahub_3 from "../../img/logo_about_2.webp";
import logoMenahub_4 from "../../img/about_2.webp";

import logoMenahub_5 from "../../img/logo_about_3.webp";
import logoMenahub_6 from "../../img/logo_about_4.webp";

import { useTranslation } from "react-i18next";
import "./about.css";

const About = () => {
  const { t } = useTranslation();

  useEffect(() => {
    let aboutGoAbout = document.querySelector(
      "#about .about_home .left .go_about"
    );

    if (aboutGoAbout) {
      let hrefValue = aboutGoAbout.getAttribute("href");

      if (hrefValue && hrefValue.includes("/about/about")) {
        aboutGoAbout.style.display = "none";
      }
    }
  }, []);

  return (
    <>
      <AboutHome />
      <div className="menahub">
        <div className="logo">
          <img src={logoMenahub} alt="logoMenahub" />
        </div>

        <div className="text">
          <p>{t("about_us_text_1")}</p>

          <div className="text_2">
            <p
              dangerouslySetInnerHTML={{
                __html: t("about_us_text_2").replace(/\n/g, "<br>"),
              }}
            />
            <p>{t("about_us_text_3")}</p>
          </div>
        </div>

        <img src={logoMenahub_2} alt="logoMenahub_2" />
      </div>

      <div className="menahub oryx">
        <div className="logo">
          <img src={logoMenahub_3} alt="logoMenahub" />
        </div>

        <img src={logoMenahub_4} alt="logoMenahub_2" />

        <div className="text">
          <p className="title">{t("about_us_text_4")}</p>

          <div className="text_2">
            <p
              dangerouslySetInnerHTML={{
                __html: t("about_us_text_5").replace(/\n/g, "<br>"),
              }}
            />
            <p
              dangerouslySetInnerHTML={{
                __html: t("about_us_text_6").replace(/\n/g, "<br>"),
              }}
            />
          </div>
        </div>
      </div>

      <div className="menahub oryx">
        <div className="logo anahid">
          <img src={logoMenahub_5} alt="logoMenahub" />
        </div>

        <div className="text">
          <p className="title">{t("about_us_text_7")}</p>

          <div className="text_2">
            <p
              dangerouslySetInnerHTML={{
                __html: t("about_us_text_8").replace(/\n/g, "<br>"),
              }}
            />
          </div>
        </div>
      </div>

      <div className="menahub oryx">
        <div className="logo nero">
          <img src={logoMenahub_6} alt="logoMenahub" />
        </div>

        <div className="text">
          <p className="title">{t("about_us_text_9")}</p>

          <div className="text_2">
            <p
              dangerouslySetInnerHTML={{
                __html: t("about_us_text_10").replace(/\n/g, "<br>"),
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
