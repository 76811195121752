import React from "react";
import "./eventFlow.css";
import { useTranslation } from "react-i18next";
import event_flow_1 from "../../../img/event_flow_1.webp";
import event_flow_2 from "../../../img/event_flow_2.webp";
import logo_l from "../../../img/logo_l.svg";

const EventFlow = () => {
  const { t } = useTranslation();

  return (
    <div id="event_flow">
      <h1>{t("event_flow")}</h1>
      <div className="event_flow">
        <div className="left">
          <div className="top">
            <h2>{t("event_flow")}</h2>
            <img src={logo_l} alt="logo_l" />
          </div>

          <div className="botton_day">
            <div className="day">
              <div className="day_1">
                <p>{t("day_1")}</p>
                <small>01.02.2024</small>
                <p>{t("day_1_t")}</p>
              </div>
              <div className="day_2">
                <p>{t("day_2")}</p>
                <small>02.02.2024</small>
                <p>{t("day_2_t")}</p>
              </div>
            </div>
            <div className="day">
              <div className="day_1">
                <p>{t("day_3")}</p>
                <small>03.02.2024</small>
                <p>{t("day_2_t")}</p>
              </div>
              <div className="day_2">
                <p>{t("day_3")}</p>
                <small>04.02.2024</small>
                <p>{t("day_2_t")}</p>
              </div>
            </div>
          </div>

          <p className="event_flow_1">{t("event_flow_1")}</p>

          <div className="botton_day_ar">
            <div className="day_1">
              <p>{t("day_1")}</p>
              <small>01.02.2024</small>
              <p>{t("day_1_t")}</p>
            </div>
            <div className="day_2">
              <p>{t("day_2")}</p>
              <small>02.02.2024</small>
              <p>{t("day_2_t")}</p>
            </div>

            <div className="day_1">
              <p>{t("day_3")}</p>
              <small>03.02.2024</small>
              <p>{t("day_2_t")}</p>
            </div>

            <div className="day_2_ar">
              <div className="day_2">
                <p>{t("day_3")}</p>
                <small>04.02.2024</small>
                <p>{t("day_2_t")}</p>
              </div>

              <p className="event_flow_1_ar">{t("event_flow_1")}</p>
            </div>
          </div>
        </div>

        <div className="right">
          <img src={event_flow_1} alt="event_flow_1" />
        </div>
      </div>
      <div className="event_flow bottom">
        <div className="left">
          <div className="title">
            <h2>{t("event_flow_2")}</h2>
            <h2>{t("event_flow_3")}</h2>
          </div>

          <div className="info">
            <p>{t("event_flow_4")}</p>
            <p>{t("event_flow_5")}</p>
          </div>

          <p>WWW.MDEVENTS.QA</p>
        </div>
        <div className="right">
          <img src={event_flow_2} alt="event_flow_2" />
        </div>
      </div>

      <table>
        <thead>
          <tr>
            <th className="date">{t("date")}</th>
            <th className="exhibition">{t("exhibition")}</th>
            <th className="audience">{t("audience")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{t("date_1")}</td>
            <td>{t("exhibition_1")}</td>
            <td>{t("audience_1")}</td>
          </tr>
          <tr>
            <td>{t("date_2")}</td>
            <td>{t("exhibition_2")}</td>
            <td>{t("audience_1")}</td>
          </tr>
          <tr>
            <td>{t("date_3")}</td>
            <td>{t("exhibition_3")}</td>
            <td>{t("audience_1")}</td>
          </tr>
          <tr>
            <td>{t("date_4")}</td>
            <td>{t("exhibition_4")}</td>
            <td>{t("audience_1")}</td>
          </tr>
        </tbody>
      </table>

      <a
        href="https://api.whatsapp.com/send?phone=97430059780"
        target="_blank"
        rel="noopener noreferrer"
        className="whatsapp_register"
      >
        <p
          dangerouslySetInnerHTML={{
            __html: t("register").replace(/\n/g, "<br>"),
          }}
        />
      </a>
    </div>
  );
};

export default EventFlow;
