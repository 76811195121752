export const englishDataEn = {
  home: "Home",
  about_us: "About Us",
  showcasing: "Showcasing",
  layout: "Layout",
  event_flow: "Event Flow",
  discover_now: "Discover Now",
  header_text_1: "Where Luxury Meets Elegance",
  header_text_2: "MD EXHIBITTION",
  header_text_3: "PULLMAN, WEST BAY, DOHA QATAR",
  header_text_4: "FEBRUARY 1-4, 2024",
  learn_more: "Learn More",
  /////////////////////
  about_text_1: "AN EXCEPTIONAL EXPERIENCE",
  about_text_2:
    "The first-of-its-kind exhibition that epitomizes the pinnacle of luxury and elegance.",
  about_text_3:
    "Immerse yourself in a world where opulence meets innovation, curated to unveil the finest in lifestyle, fashion, accessories, jewelry, health, beauty, and more, in an experience never before seen.",
  about_text_4:
    "Join us in the heart of Qatar as we celebrate a seamless blend of sophistication and creativity, inviting you to experience a world where the extraordinary is the norm",
  about_text_5: "THERE`S MORE TO IT THAN MEETS THE EYE!",
  /////////////////////
  arab_fashion: "Arab Fashion",
  international_fashion: "International Fashion",
  /////////////////////
  layout: "Layout",
  /////////////////////
  event_flow: "Event flow",
  day_1: "DAY 1",
  day_2: "DAY 2",
  day_3: "DAY 3",
  day_4: "DAY 4",
  day_1_t: "INAUGURATION",
  day_2_t: "LIVE PERFORMANCES",
  event_flow_1:
    "* The exhibition is open to the public and includes folklore performances every day",
  event_flow_2: "A GOLDEN",
  event_flow_3: "OPPORTUNITY",
  event_flow_4:
    "In this gathering you will find and get to know famous and creative designers, suppliers, and services providers in multiple filds.",
  event_flow_5:
    "Discover the limitless possibilities of global brands with profitable sales and broad benefits.",
  ///////////////////
  date: "The Date",
  date_1: "Thursday, Feb, 1",
  date_2: "Friday, Feb, 2",
  date_3: "Saturday, Feb, 3",
  date_4: "Sunday, Feb, 4",
  ////
  exhibition: "Exhibition Timing",
  exhibition_1: "11 am to 10 pm",
  exhibition_2: "2 pm to 11 pm",
  exhibition_3: "10 am to 10 pm",
  exhibition_4: "10 am to 10 pm",
  ////
  audience: "The Audience",
  audience_1: "General",
  register: `REGISTER
NOW!`,
  /////////////////
  about_us_text_1:
    "Represented by Ms. Asmaa Al Kuwari, with her dynamic presence in the world of business and fashion. As the creative mind behind Asma W-Design, Asma works to nurture local designers with her pioneering spirit and experience, which makes her a driving force for positive change and growth in Qatar and beyond.",
  about_us_text_2: "Welcome to MenaHub - Qatar's own online store.",
  about_us_text_3:
    "MenaHub stands as the epitome of e-commerce excellence focused on B2B & B2C brands. We bridge the gap between businesses and consumers, creating a vibrant marketplace where industry experts and individual shoppers alike can connect, collaborate, and thrive. With a far-reaching network of vendors spanning Qatar, India and beyond, MenaHub optimizes growth for existing brands while introducing new horizons of products. As we venture into Saudi Arabia by 2024, our commitment to innovation, expertise and global success only strengthens. Discover MenaHub.com where excellence meets commerce. MenaHub is organizing this event and is proud to be associated with it.",
  /////////////////
  about_us_text_4: "ORYX DOHA FASHION 2003",
  about_us_text_5:
    "An integrated Qatari system for the modern fashion industry in accordance with a Qatari vision in which the inspiration of the future is mixed with the spirit of the past. It seeks to attract the material and human potential to make a mark in the world of fashion around the world by leading professional work teams specialized in the field of design, production, and quality management, and this was crowned by obtaining the international ISO certificate. It is accredited to carry out tenders in the State of Qatar in the field of (regular uniforms - heritage fashions), owned by Mrs. Amal Saleh Al-Yafei.",
  about_us_text_6:
    "She also owns a series of fashion companies inside and outside the State of Qatar, including Oryx Doha Fashion Lilac Fashion, Yafi Trading, and Yafi Academy for Administrative and Vocational Training. Additionally, she has experience in organizing and holding local and international exhibitions and events.",
  /////////////////
  about_us_text_7: "ANAHID, OPERATOR AND TRADER 2016",
  about_us_text_8:
    "A Qatari company specializing in the fashion industry with quality, accuracy and reasonable prices. It has brands such as Naranja for daily and travel clothing, and a production line for uniforms such as school uniforms, hospitals, companies, hotels and hospitality, managed by Mrs. Tarfa Saad Al-Zarraa, she also owns, Altarfa  company for preparing and organizing  exhibitions, conferences, workshops and events. the company started working officially in 2023 by holding a WD  exhibition from 5 to 8 February 2023, and the Boutique H Style Exhibition on October 28, 2023",
  /////////////////
  about_us_text_9: "NERO FASHION 2015",
  about_us_text_10: `
  A Qatari company specializing in designing and sewing women's fashions. It was established and managed by Mrs.
  Aisha Al-Mutawa, who has won AIGNER's trust. Nero Fashion became AIGNER's approved designer for their Qatari abayas and also contributed to providing Qatari fashions to some important figures and wives of well-known presidents, as well as international champions in the sports field.
  Additionally, Mrs. Aisha has been the founder of several sports projects that care for the family since 2018.
  `,
};

export default englishDataEn;
